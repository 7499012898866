var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"availability full-screen-dialog",attrs:{"visible":_vm.visible,"before-close":_vm.handleClose,"append-to-body":true,"width":"100%"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"caption"},[_c('h4',[_vm._v("Welke dagdelen moet er vervangen worden?")])]),_c('h5',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.capitalize(_vm.editingDay.day.format("dddd D MMMM")))+" ")]),_c('p',{staticClass:"mt-4"},[_vm._v(" 'S ochtends "),_c('span',{staticClass:"daypart"},[_vm._v("(08:00 - 12:30)")])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"btn",class:{
            'btn-primary': _vm.editingDay.dayParts.morning.value,
            'btn-outline-primary': !_vm.editingDay.dayParts.morning.value,
            disabled: _vm.editingDay.dayParts.morning.disabled
          },attrs:{"disabled":_vm.editingDay.dayParts.morning.disabled},on:{"click":function($event){$event.preventDefault();!_vm.editingDay.dayParts.morning.disabled
              ? _vm.setAvailability('morning', true)
              : ''}}},[_vm._v(" Vervangen")])]),_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"btn",class:{
            'btn-primary': _vm.editingDay.dayParts.morning.value == false,
            'btn-outline-primary': _vm.editingDay.dayParts.morning.value != false,
            disabled: _vm.editingDay.dayParts.morning.disabled
          },attrs:{"disabled":_vm.editingDay.dayParts.morning.disabled},on:{"click":function($event){$event.preventDefault();!_vm.editingDay.dayParts.morning.disabled
              ? _vm.setAvailability('morning', false)
              : ''}}},[_vm._v(" Niet vervangen")])])]),_c('p',{staticClass:"mt-4"},[_vm._v(" 'S middags "),_c('span',{staticClass:"daypart"},[_vm._v("(13:00 - 17:30)")])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"btn",class:{
            'btn-primary': _vm.editingDay.dayParts.afternoon.value,
            'btn-outline-primary': !_vm.editingDay.dayParts.afternoon.value,
            disabled: _vm.editingDay.dayParts.afternoon.disabled
          },on:{"click":function($event){$event.preventDefault();!_vm.editingDay.dayParts.afternoon.disabled
              ? _vm.setAvailability('afternoon', true)
              : ''}}},[_vm._v(" Vervangen")])]),_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"btn",class:{
            'btn-primary': _vm.editingDay.dayParts.afternoon.value == false,
            'btn-outline-primary':
              _vm.editingDay.dayParts.afternoon.value != false,
            disabled: _vm.editingDay.dayParts.afternoon.disabled
          },on:{"click":function($event){$event.preventDefault();!_vm.editingDay.dayParts.afternoon.disabled
              ? _vm.setAvailability('afternoon', false)
              : ''}}},[_vm._v(" Niet vervangen")])])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"row justify-content-between align-items-right"},[_c('div',{staticClass:"col"},[_c('a',{staticClass:"btn btn-warning",attrs:{"data-toggle":"modal","data-target":"#exampleModal"},on:{"click":function($event){$event.preventDefault();return _vm.saveDayParts($event)}}},[_vm._v("Opslaan")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }