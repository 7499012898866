































































import moment, { Moment } from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";

import CalendarDialog from "@/common/components/Calendar/CalendarDialog.vue";

@Component({
  components: {
    CalendarDialog
  }
})
export default class Step3 extends Vue {
  @Prop() newRequest!: {
    period: {
      eventType: string;
      color: string;
      days: {
        day: Moment;
        dayParts: {
          morning: boolean;
          afternoon: boolean;
        };
      }[];
    }[];
  };

  dialogVisible = false;
  settingDateFor = "startDate";

  currentMonth: Moment = moment().startOf("month");

  previousMonth() {
    this.currentMonth = this.currentMonth.clone().subtract(1, "M");
  }

  nextMonth() {
    this.currentMonth = this.currentMonth.clone().add(1, "M");
  }

  openCalendarDialog(settingDateFor: string) {
    this.settingDateFor = settingDateFor;
    this.currentMonth =
      settingDateFor === "startDate"
        ? this.getStartOfPeriod()
            .clone()
            .startOf("month")
        : this.getEndOfPeriod()
            .clone()
            .startOf("month");
    this.dialogVisible = true;
  }

  async changeDate(date: Moment) {
    if (this.settingDateFor === "startDate") {
      const daysBetween = this.getDaysBetweenDates(date, date);
      this.$emit("update", {
        periodStart: date,
        period: [
          {
            ...this.newRequest.period[0],
            days: daysBetween
          }
        ]
      });

      this.settingDateFor = "endDate";
    } else if (this.settingDateFor === "endDate") {
      const daysBetween = this.getDaysBetweenDates(
        this.getStartOfPeriod(),
        date
      );

      this.$emit("update", {
        periodEnd: date,
        period: [
          {
            ...this.newRequest.period[0],
            days: daysBetween
          }
        ]
      });

      this.dialogVisible = false;
    }
  }

  getDaysBetweenDates(startDate: Moment, endDate: Moment) {
    const date = [];
    while (moment(startDate) <= moment(endDate)) {
      date.push({
        day: startDate,
        dayParts: {
          morning: true,
          afternoon: true
        }
      });
      startDate = moment(startDate).add(1, "days");
    }
    return date;
  }

  getStartOfPeriod() {
    return this.newRequest.period[0].days[0].day;
  }

  getEndOfPeriod() {
    return this.newRequest.period[0].days[
      this.newRequest.period[0].days.length - 1
    ].day;
  }
}
