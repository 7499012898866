












































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Step1 extends Vue {
  @Prop() newRequest!: {};

  setType(type: string) {
    this.$emit("update", {
      requestType: type
    });

    this.$emit("nextStep");
  }
}
