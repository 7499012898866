








































































import { filter, find, includes, map } from "lodash";
import moment, { Moment } from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Step6 extends Vue {
  @Prop() newRequest!: {
    classes: string[];
    location: string;
  };
  @Prop() absenceOptions!: {
    classes: { id: string; display: string }[];
    locations: { id: string; display: string }[];
  };

  formatDate(date: Moment, format: string) {
    return moment(date).format(format);
  }

  getLocation() {
    const location = find(this.absenceOptions.locations, {
      id: this.newRequest.location
    });

    return location ? location.display : "";
  }

  getClasses() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return map(
      filter(this.absenceOptions.classes, function(absenceClass) {
        return includes(self.newRequest.classes, absenceClass.id);
      }),
      function(absenceClass: { id: string; display: string }) {
        return absenceClass.display;
      }
    ).join(", ");
  }
}
