



























































import { AxiosError, AxiosResponse } from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

import axios from "@/libs/axios/axios";
import { State } from "vuex-class";
import { UserState } from "@/common/store/user/types";
import moment, { Moment } from "moment";

@Component({
  components: {}
})
export default class Step2 extends Vue {
  @State("user") user!: UserState;
  @Prop() newRequest!: {};

  searchingUser = "";
  selectedUser = {
    id: 0,
    name: ""
  };

  contracts: {
    id: number;
    school: {
      name: string;
      brin: string;
    };
    totalFte: string;
    workingDays: string[];
    startDate: string;
    endDate: string;
  }[] = [];

  getEmployees(queryString: string, cb: Function) {
    if (
      queryString.length >= 3 &&
      this.user.currentTenant &&
      this.user.currentTenant.id
    ) {
      axios
        .get("absences/create/employees", {
          params: {
            q: queryString
          },
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        })
        .then(function(response: void | AxiosResponse) {
          if (response && response.data) {
            cb(response.data.item);
          }
        });
    } else {
      cb([]);
    }
  }

  async handleSelect(value: { id: number; name: string }) {
    this.selectedUser = value;

    if (this.user.currentTenant && this.user.currentTenant.id) {
      const response: void | AxiosResponse = await axios
        .get(
          "absences/create/employees/" + this.selectedUser.id + "/contracts",
          {
            headers: {
              "X-VVTOOL-TENANT": this.user.currentTenant.id
            }
          }
        )
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        this.contracts = response.data.items;
      }
    }
  }

  formatDate(date: Moment, format: string) {
    return moment(date).format(format);
  }

  selectContract(contract: {}) {
    this.$emit("update", {
      contract,
      selectedUser: this.selectedUser
    });

    this.$emit("nextStep");
  }
}
