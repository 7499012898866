var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"new-request"}},[_c('div',{staticClass:"container"},[_c('h4',{staticClass:"mb-3"},[_vm._v("Nieuwe melding")]),_c('ul',{staticClass:"list list-unstyled m-0 pt-4"},[_c('li',{staticClass:"item"},[_c('a',{on:{"click":function($event){$event.preventDefault();_vm.dialogVisible = true}}},[_vm._v("Vervangingsverzoek aanmaken")])])])]),_c('el-dialog',{staticClass:"new-request-dialog full-screen-dialog",attrs:{"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"append-to-body":true,"width":"100%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c(_vm.getStep(),{tag:"Component",attrs:{"newRequest":_vm.newRequest,"absenceOptions":_vm.absenceOptions},on:{"update":_vm.updateRequest,"updateAbsenceOptions":_vm.updateAbsenceOptions,"nextStep":_vm.nextStep}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-auto"},[(_vm.activeStep > 1)?_c('a',{staticClass:"back",on:{"click":function($event){$event.preventDefault();return _vm.previousStep($event)}}},[_c('i',{staticClass:"fas fa-arrow-alt-left"}),_vm._v(" Vorige stap")]):_vm._e()]),_c('div',{staticClass:"col-auto text-right"},[(
              _vm.activeStep < 6 &&
                _vm.activeStep != 1 &&
                _vm.activeStep != 2 &&
                (_vm.activeStep != 5 ||
                  (_vm.activeStep == 5 &&
                    _vm.newRequest.classes.length > 0 &&
                    _vm.newRequest.location != 0)) &&
                (_vm.activeStep != 4 || (_vm.activeStep == 4 && _vm.dayPartIsFilled))
            )?_c('a',{staticClass:"btn btn-warning",attrs:{"data-toggle":"modal","data-target":"#exampleModal"},on:{"click":function($event){$event.preventDefault();return _vm.nextStep($event)}}},[_vm._v("Volgende "),_c('i',{staticClass:"far fa-angle-right"})]):_vm._e(),(_vm.activeStep == 6)?_c('a',{staticClass:"btn btn-warning",attrs:{"data-toggle":"modal","data-target":"#exampleModal"},on:{"click":function($event){$event.preventDefault();return _vm.finishRequest($event)}}},[_vm._v("Opslaan")]):_vm._e()])])])],1),_c('el-dialog',{staticClass:"confirm-close-dialog",attrs:{"visible":_vm.dialogConfirmCloseVisible,"before-close":_vm.handleConfirmClose,"append-to-body":true,"show-close":false,"width":"80%"},on:{"update:visible":function($event){_vm.dialogConfirmCloseVisible=$event}}},[_c('div',{staticClass:"text-row text-center"},[_c('h5',[_vm._v("Let op!")]),_c('p',[_vm._v("Weet je zeker dat je wilt afsluiten?")])]),_c('el-button',{attrs:{"width":"100%","type":"warning"},on:{"click":function($event){return _vm.saveAndConfirmClose(false)}}},[_vm._v("Afsluiten")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{on:{"click":function($event){_vm.dialogConfirmCloseVisible = false}}},[_vm._v("Nee, ga verder")])])],1),_c('SavedDialog',{attrs:{"title":"Succesvol opgeslagen","text":'Bedankt ' + _vm.userName + ', je verzoek is succesvol opgeslagen.',"buttonText":"Terug naar dashboard","visible":_vm.dialogSavedVisible},on:{"handleSaveClose":_vm.handleSaveClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }