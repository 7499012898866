










































import { Component, Vue, Prop } from "vue-property-decorator";

import Calendar from "@/common/components/Calendar/Calendar.vue";
import moment, { Moment } from "moment";
import { find, findIndex, map } from "lodash";

import DaysWithEvent from "@/common/interfaces/DaysWithEvent";
import NewRequestDialog from "@/common/components/Dialogs/NewRequestDialog.vue";

interface DayInterface {
  day: Moment;
  dayParts: {
    morning: boolean;
    afternoon: boolean;
  };
}

@Component({
  components: { Calendar, NewRequestDialog }
})
export default class Step4 extends Vue {
  @Prop() newRequest!: {
    periodStart: Moment;
    period: DaysWithEvent[];
  };

  availabilityDialogVisible = false;
  editingDay:
    | {
        day: Moment;
        dayParts: {
          morning: {
            value: boolean;
            disabled: boolean;
          };
          afternoon: {
            value: boolean;
            disabled: boolean;
          };
        };
      }
    | boolean = false;
  currentMonth = moment().startOf("month");

  mounted() {
    this.currentMonth = this.newRequest.periodStart.clone().startOf("month");
  }

  get daysWithEvents() {
    return [
      {
        eventType: "single-event",
        color: "green",
        days: this.newRequest.period[0].days.map(day => {
          return {
            ...day,
            dayParts: {
              morning: day.dayParts.morning === true,
              afternoon: day.dayParts.afternoon === true
            }
          };
        })
      },
      {
        eventType: "single-event",
        color: "blue",
        days: this.newRequest.period[0].days.map(day => {
          return {
            ...day,
            dayParts: {
              morning: day.dayParts.morning === false,
              afternoon: day.dayParts.afternoon === false
            }
          };
        })
      }
    ];
  }

  previousMonth() {
    this.currentMonth = this.currentMonth.clone().subtract(1, "M");
  }

  nextMonth() {
    this.currentMonth = this.currentMonth.clone().add(1, "M");
  }

  handleAvailabilityClose() {
    this.availabilityDialogVisible = false;
  }

  handleClose() {
    this.$emit("update:visible", false);
  }

  getStartOfPeriod() {
    return this.newRequest.period[0].days[0];
  }

  getEndOfPeriod() {
    return this.newRequest.period[0].days[
      this.newRequest.period[0].days.length - 1
    ];
  }

  setAvailability(value: { dayPart: string; value: boolean }) {
    if (typeof this.editingDay === "object") {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      const foundIndex = findIndex(this.newRequest.period[0].days, function(
        day: DayInterface
      ) {
        if (typeof self.editingDay === "object") {
          return day.day.isSame(self.editingDay.day, "day");
        } else {
          return false;
        }
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      this.editingDay.dayParts[value.dayPart].value = value.value;

      this.$emit("update", {
        period: [
          {
            ...this.newRequest.period[0],
            days: map(this.newRequest.period[0].days, function(
              day: DayInterface,
              index: number
            ) {
              if (index === foundIndex) {
                return {
                  ...day,
                  dayParts: {
                    ...day.dayParts,
                    [value.dayPart]: value.value
                  }
                };
              } else {
                return day;
              }
            })
          }
        ]
      });
    }
  }

  saveDayParts() {
    this.availabilityDialogVisible = false;
  }

  selectDate(day: Moment) {
    const found = find(this.newRequest.period[0].days, { day: day });
    if (found) {
      this.editingDay = {
        day: found.day,
        dayParts: {
          morning: {
            value: found.dayParts.morning,
            disabled: false
          },
          afternoon: {
            value: found.dayParts.afternoon,
            disabled: false
          }
        }
      };
    } else {
      this.editingDay = false;
    }

    this.availabilityDialogVisible = true;
  }
}
