



















































import { UserState } from "@/common/store/user/types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/libs/axios/axios";
import moment, { Moment } from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

@Component({
  components: {}
})
export default class Step5 extends Vue {
  @State("user") user!: UserState;
  @Prop() newRequest!: {};
  absenceOptions: {
    classes: { id: string; display: string }[];
    locations: { id: string; display: string }[];
  } = {
    classes: [],
    locations: []
  };

  async mounted() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const response: void | AxiosResponse = await axios
        .get("absences/create/options", {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        this.absenceOptions = response.data;
        this.$emit("updateAbsenceOptions", response.data);

        if (this.absenceOptions.locations.length === 1) {
          this.updateLocations(this.absenceOptions.locations[0].id);
        }
      }
    }
  }

  updateLocations(value: string) {
    this.$emit("update", {
      location: value
    });
  }

  updateClasses(value: []) {
    this.$emit("update", {
      classes: value
    });
  }

  formatDate(date: Moment, format: string) {
    return moment(date).format(format);
  }
}
