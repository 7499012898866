







































































































import { Component, Vue } from "vue-property-decorator";
import moment, { Moment } from "moment";
import Step1 from "./Steps/Step1.vue";
import Step2 from "./Steps/Step2.vue";
import Step3 from "./Steps/Step3.vue";
import Step4 from "./Steps/Step4.vue";
import Step5 from "./Steps/Step5.vue";
import Step6 from "./Steps/Step6.vue";
import { Getter, State } from "vuex-class";
import { LoginState } from "@/common/store/login/types";

import SavedDialog from "@/common/components/Dialogs/SavedDialog.vue";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/libs/axios/axios";
import { forEach, map } from "lodash";
import { UserState } from "@/common/store/user/types";

@Component({
  components: {
    SavedDialog
  }
})
export default class NewRequest extends Vue {
  @State("user") user!: UserState;
  @State("login") login!: LoginState;
  @Getter("getUserName", { namespace: "user" }) userName!: string;
  dialogConfirmCloseVisible = false;
  dialogSavedVisible = false;
  dialogVisible = false;
  activeStep = 1;
  newRequest = this.getNewRequest();
  absenceOptions: {
    classes: { id: string; display: string }[];
    locations: { id: string; display: string }[];
  } = {
    classes: [],
    locations: []
  };

  async mounted() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      await axios
        .get("absences", {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    }
  }

  handleClose() {
    this.dialogConfirmCloseVisible = true;
  }

  handleConfirmClose() {
    this.dialogConfirmCloseVisible = false;
  }

  confirmClose() {
    this.newRequest = this.getNewRequest();
    this.activeStep = 1;
    this.dialogVisible = false;
    this.dialogConfirmCloseVisible = false;
  }

  get dayPartIsFilled() {
    let dayPartIsFilled = false;

    forEach(this.newRequest.period[0].days, function(day) {
      if (day.dayParts.morning || day.dayParts.afternoon) {
        dayPartIsFilled = true;
      }
    });

    return dayPartIsFilled;
  }

  async saveAndConfirmClose(concept: boolean) {
    //TODO: save request (either local storage or maybe API?)
    if (concept) {
      this.newRequest.concept = true;
      const response = await this.saveRequest();

      if (response) {
        this.dialogSavedVisible = true;
        this.dialogConfirmCloseVisible = false;
      }
    } else {
      this.dialogVisible = false;
      this.dialogConfirmCloseVisible = false;
    }

    this.newRequest = this.getNewRequest();
    this.activeStep = 1;
  }

  handleSaveClose() {
    this.$router.push({ name: "Dashboard" });
  }

  getStep() {
    switch (this.activeStep) {
      case 1:
        return Step1;
      case 2:
        return Step2;
      case 3:
        return Step3;
      case 4:
        return Step4;
      case 5:
        return Step5;
      case 6:
        return Step6;
    }
  }

  updateRequest(value: {}) {
    this.newRequest = {
      ...this.newRequest,
      ...value
    };
  }

  updateAbsenceOptions(value: {
    classes: { id: string; display: string }[];
    locations: { id: string; display: string }[];
  }) {
    this.absenceOptions = value;
  }

  getNewRequest() {
    const newRequest: {
      requestType: string | null;
      concept: boolean;
      contract: {
        id: number;
      };
      classes: string[];
      location: string | null;
      periodStart: Moment;
      periodEnd: Moment;
      period: {
        eventType: string;
        color: string;
        days: {
          day: Moment;
          dayParts: {
            morning: boolean;
            afternoon: true;
          };
        }[];
      }[];
    } = {
      requestType: null,
      concept: false,
      contract: {
        id: 0
      },
      classes: [],
      location: null,
      periodStart: this.getStartDate(),
      periodEnd: this.getEndDate(),
      period: [
        {
          eventType: "long-event",
          color: "blue",
          days: [
            {
              day: this.getStartDate(),
              dayParts: {
                morning: true,
                afternoon: true
              }
            },
            {
              day: this.getEndDate(),
              dayParts: {
                morning: true,
                afternoon: true
              }
            }
          ]
        }
      ]
    };

    return newRequest;
  }

  async finishRequest() {
    const response = await this.saveRequest();

    if (response) {
      this.dialogSavedVisible = true;
      this.dialogConfirmCloseVisible = false;
    }
  }

  getStartDate() {
    let startDate = moment();
    if (startDate.day() == 0) {
      startDate = startDate.add(1, "days");
    } else if (startDate.day() == 6) {
      startDate = startDate.add(2, "days");
    }
    return startDate.startOf("day");
  }

  getEndDate() {
    let endDate = moment().add(1, "day");
    if (endDate.day() == 0) {
      endDate = endDate.add(1, "days");
    } else if (endDate.day() == 6) {
      endDate = endDate.add(2, "days");
    }
    return endDate.startOf("day");
  }

  previousStep() {
    this.activeStep--;
  }

  nextStep() {
    this.activeStep++;
  }

  async saveRequest() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const dayParts: { date: string; period: number }[] = [];
      await Promise.all(
        map(this.newRequest.period[0].days, function(day) {
          if (day.dayParts.morning) {
            dayParts.push({
              date: moment(day.day).format("DD-MM-YYYY"),
              period: 1
            });
          }
          if (day.dayParts.afternoon) {
            dayParts.push({
              date: moment(day.day).format("DD-MM-YYYY"),
              period: 2
            });
          }
        })
      );

      const response: void | AxiosResponse = await axios
        .post(
          "absences",
          {
            contractId: this.newRequest.contract.id,
            kind: this.newRequest.requestType,
            periodStart: this.newRequest.periodStart.format("DD-MM-YYYY"),
            periodEnd: this.newRequest.periodEnd.format("DD-MM-YYYY"),
            concept: this.newRequest.concept,
            classes: this.newRequest.classes,
            location: this.newRequest.location,
            dagdelen: dayParts
          },
          {
            headers: {
              "X-VVTOOL-TENANT": this.user.currentTenant.id
            }
          }
        )
        .catch((error: AxiosError) => {
          console.log(error);
        });

      return response;
    } else {
      return false;
    }
  }
}
